<template>
  <div class="blockInfo">
    <span v-if="title" class="bockTitle" style="margin-bottom: 5px">{{
      title
    }}</span>
    <div style="display: flex">
      <input
        :type="type ? type : 'text'"
        :name="title"
        :value="value"
        :maxlength="maxlength"
        class="bockInput"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="off"
        v-on:input="updateValue($event.target.value)"
        @keyup="$emit('input-change', $event.target.value)"
        ref="inputRef"
        v-on:keyup.enter="add"
      />

      <CButton
        v-if="buttonDelete"
        size="sm"
        class="buttonDelete"
        @click="removeElement(_key)"
      >
        <CIcon name="cil-trash" />
      </CButton>
      <CButton
        v-if="buttonAdd"
        color="primary"
        class="px-4 button-add"
        @click="add"
        >Add</CButton
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockInfo",
  props: {
    title: String,
    _key: String,
    value: {
      type: [Number, String],
    },
    placeholder: String,
    disabled: Boolean,
    buttonDelete: Boolean,
    buttonAdd: Boolean,
    type: String,
    maxlength: Number,
    filterType: String,
  },
  data() {
    return {
      val: null,
    };
  },
  computed: {},
  methods: {
    updateValue(value) {
      this.val = value;
      this.$emit("input", value);
    },
    removeElement(_key) {
      this.$emit("block-info-remove", _key);
    },
    add() {
      this.$refs.inputRef.value = null;
      this.$emit("add", this.val, this.filterType);
    },
  },
};
</script>

<style scoped>
.blockInfo {
  padding-top: 0.5em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;

  /* display: flex; */
  justify-content: center;
  align-items: center;

  font-size: 16px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);

  position: relative;
}
.bockTitle {
  /* width: 50%; */

  display: block;
}
.button-add {
  margin-left: 5px;
}
.bockInput {
  border: none;
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  font-size: 14px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgb(124, 124, 124);
}
.bockInput:active,
.bockInput:hover,
.bockInput:focus {
  border: none;
  outline: none;
}

.buttonDelete {
  position: absolute;
  right: 0.5em;
  cursor: pointer;
}
</style>