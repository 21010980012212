<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">Filters</b>
        <CButton
          color="primary"
          style="float: right"
          class="mb-2"
          @click="collapse = !collapse"
        >
          {{ collapse == true ? "Hide" : "Show" }}
        </CButton>
      </CCardHeader>
      <CCollapse :show="collapse">
        <CCardBody>
          <CRow>
            <div class="col-4">
              <BlockInfo
                title="Truck plate"
                filterType="trucks"
                type="text"
                :buttonAdd="true"
                @add="addFilter"
              />
              <ul id="element-list">
                <li v-for="item in filters.trucks" :key="item">
                  <CButton
                    size="sm"
                    class="buttonDelete"
                    @click="removeFilter(item, 'trucks')"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                  {{ item }}
                </li>
              </ul>
            </div>

            <div class="col-4">
              <BlockInfo
                title="Plants"
                filterType="plants"
                type="text"
                :buttonAdd="true"
                @add="addFilter"
              />
              <ul id="element-list">
                <li v-for="item in filters.plants" :key="item">
                  <CButton
                    size="sm"
                    class="buttonDelete"
                    @click="removeFilter(item, 'plants')"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="col-4">
              <BlockInfo
                title="Delivery note"
                filterType="deliveryNotes"
                type="text"
                :buttonAdd="true"
                @add="addFilter"
              />
              <ul id="element-list">
                <li v-for="item in filters.deliveryNotes" :key="item">
                  <CButton
                    size="sm"
                    class="buttonDelete"
                    @click="removeFilter(item, 'deliveryNotes')"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="col-4">
              <BlockInfoSelect
                title="Delivery Status"
                :selected="dnStatusSelected"
                :values="dnStatus"
                @change-value="filterStatus"
              />
            </div>
            <div class="col-4">
              <BlockModal
                title="Job sites"
                filterType="shiptos"
                type="text"
                :buttonAdd="true"
                :filterStore="filters.shiptos"
                :keyField="'snumber'"
                :nameField="'sname'"
                @add="addFilter"
                @remove="removeFilter"
              />
              <ul id="element-list">
                <li v-for="item in filters.shiptos" :key="item" class="element">
                  <CButton
                    size="sm"
                    class="buttonDelete"
                    @click="removeFilter(item, 'shiptos')"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                  <span class="selected-element"
                    >[{{ item }}]
                    {{
                      dictionaries.shiptos.filter((x) => x.key == item)[0].value
                    }}</span
                  >
                </li>
              </ul>
            </div>
            <div class="col-4">
              <BlockModal
                title="Customers"
                filterType="soldtos"
                type="text"
                :buttonAdd="true"
                :filterStore="filters.soldtos"
                :keyField="'snumber'"
                :nameField="'sname'"
                @add="addFilter"
                @remove="removeFilter"
              />
              <ul id="element-list">
                <li v-for="item in filters.soldtos" :key="item" class="element">
                  <CButton
                    size="sm"
                    class="buttonDelete"
                    @click="removeFilter(item, 'soldtos')"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                  <span class="selected-element"
                    >[{{ item }}]
                    {{
                      dictionaries.soldtos.filter((x) => x.key == item)[0].value
                    }}</span
                  >
                </li>
              </ul>
            </div>
            <div class="col-4">
              <BlockModal
                title="Carriers"
                filterType="carriers"
                type="text"
                :buttonAdd="true"
                :filterStore="filters.carriers"
                :keyField="'vendor_id'"
                :nameField="'vendor_name'"
                @add="addFilter"
                @remove="removeFilter"
              />
              <ul id="element-list">
                <li
                  v-for="item in filters.carriers"
                  :key="item"
                  class="element"
                >
                  <CButton
                    size="sm"
                    class="buttonDelete"
                    @click="removeFilter(item, 'carriers')"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                  <span class="selected-element"
                    >[{{ item }}]
                    {{
                      dictionaries.carriers.filter((x) => x.key == item)[0]
                        .value
                    }}</span
                  >
                </li>
              </ul>
            </div>

            <div class="col-4">
              <BlockModal
                title="Forwarding agents"
                filterType="forwardingagents"
                type="text"
                :buttonAdd="true"
                :filterStore="filters.forwardingagents"
                :keyField="'fa_id'"
                :nameField="'fa_name'"
                @add="addFilter"
                @remove="removeFilter"
              />
              <ul id="element-list">
                <li
                  v-for="item in filters.forwardingagents"
                  :key="item"
                  class="element"
                >
                  <CButton
                    size="sm"
                    class="buttonDelete"
                    @click="removeFilter(item, 'forwardingagents')"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                  <span class="selected-element"
                    >[{{ item }}]
                    {{
                      dictionaries.forwardingagents.filter(
                        (x) => x.key == item
                      )[0].value
                    }}</span
                  >
                </li>
              </ul>
            </div>
          </CRow>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>


<script>
import BlockInfo from "./BlockInfo.vue";
import BlockInfoSelect from "./BlockInfoSelect.vue";

import BlockModal from "./BlockModal.vue";
import ModalSearch from "./ModalSearch.vue";
import * as Socket from "../plugins/socket_client";
import { store } from "../store/store";

export default {
  name: "Filters",
  components: { BlockInfo, BlockInfoSelect, ModalSearch, BlockModal },
  props: {},
  computed: {
    filters() {
      return store.state.filters;
    },
    dictionaries() {
      return store.state.dictionaries;
    },
  },
  mounted() {},
  data() {
    return {
      dnStatusSelected: "all",
      dnStatus: [
        { _key: "all", value: "All" },
        { _key: "tripStarted", value: "Waiting on plant" },
        { _key: "loadStarted,loadEnded", value: "Loading in plant" },
        { _key: "loadingLocationLeft", value: "Going to job site" },
        { _key: "unloadingLocationEntered", value: "On jobsite" },
        { _key: "unloadStarted,unloadEnded", value: "Unloading" },
        { _key: "skipSignature,deliveryAccepted", value: "Finish unloading" },
        { _key: "unloadingLocationLeft", value: "Return to plant" },
        { _key: "loadingLocationEntered", value: "On plant" },
      ],
      modalVisibleShipto: false,
      collapse: true,
    };
  },
  methods: {
    async addFilter(data, type) {
      var self = this;
      data = data.trim().toUpperCase();
      store.commit("addFilter", { type: type, value: data });
      Socket.add(type, data);
    },
    async removeFilter(data, type) {
      var self = this;
      store.commit("removeFilter", { type: type, value: data });
      Socket.remove(type, data);
    },
    filterStatus(value) {
      var type = "deliveryNoteStatus";
      store.commit("addFilter", { type: type, value: value.split(",") });
    },
  },
};
</script>
<style scoped>
#element-list {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.selected-element {
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  text-overflow: ellipsis;
}
.element {
  display: flex;
}
</style>