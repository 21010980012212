<template>
  <div class="blockInfo">
    <span v-if="title" class="bockTitle" style="margin-bottom: 5px">{{
      title
    }}</span>
    <div style="display: flex">
      <CButton
        v-if="buttonAdd"
        color="primary"
        class="px-4 button-add"
        @click="
          () => {
            this.modalVisible = true;
          }
        "
        >Open search</CButton
      >
    </div>
    <ModalSearch
    :modalTitle="title"
      :modalVisible="modalVisible"
      :searchType="filterType"
      :filter="filterStore"
      @addFilter="addFilter"
      @removeFilter="removeFilter"
      @closeModal="closeModal"
      :keyField="keyField"
      :nameField="nameField"
    />
  </div>
</template>

<script>
import ModalSearch from "./ModalSearch.vue";
export default {
  name: "BlockModal",
  components: { ModalSearch },
  props: {
    title: String,
    _key: String,
    value: {
      type: [Number, String],
    },
    placeholder: String,
    disabled: Boolean,
    buttonDelete: Boolean,
    buttonAdd: Boolean,
    type: String,
    maxlength: Number,
    filterType: String,
    filterStore: Array,
    keyField: String,
    nameField: String
  },
  data() {
    return {
      val: null,
      modalVisible: false,
    };
  },
  computed: {},
  methods: {
    updateValue(value) {
      this.val = value;
      this.$emit("input", value);
    },
    removeElement(_key) {
      this.$emit("block-info-remove", _key);
    },
    addFilter(data, type) {
      this.$emit("add", data, type);
    },
    removeFilter(data, type) {
      this.$emit("remove", data, type);
    },
    closeModal() {
      this.modalVisible = false;
    },
  },
};
</script>

<style scoped>
.blockInfo {
  padding-top: 0.5em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;

  /* display: flex; */
  justify-content: center;
  align-items: center;

  font-size: 16px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);

  position: relative;
}
.bockTitle {
  /* width: 50%; */

  display: block;
}
.button-add {
  margin-left: 5px;
}
.bockInput {
  border: none;
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  font-size: 14px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgb(124, 124, 124);
}
.bockInput:active,
.bockInput:hover,
.bockInput:focus {
  border: none;
  outline: none;
}

.buttonDelete {
  position: absolute;
  right: 0.5em;
  cursor: pointer;
}
</style>