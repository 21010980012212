<template>
  <div>
    <CModal
      :title="modalTitle"
      color="primary"
      :show.sync="modalVisible"
      :closeOnBackdrop="false"
      :size="'xl'"
    >
      <slot slot="header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="closeModal()"
        >
          ×
        </button>
      </slot>
      <CRow>
        <CCol sm="12">
          <CInput
            label="Search"
            placeholder=""
            v-model="searchStr"
            v-on:keyup.enter="search"
          />
          <CButton type="button" color="primary" class="btn btn-info" @click="search">
            Search {{ modalTitle }}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <!-- v-if="resultTable.length > 0" -->
        <CCol sm="12">
          <CDataTable
            :items="resultTable"
            :fields="tableFields"
            items-per-page-select
            :items-per-page="5"
            hover
            sorter
            pagination
          >
            <template #actions="{ item }">
              <td>
                <CButton
                  v-if="!filter.includes(item[keyField])"
                  type="button"
                    color="primary"
                  class="btn btn-info"
                  @click="addFilter(item[keyField])"
                >
                  Add
                </CButton>
                <CButton
                  v-if="filter.includes(item[keyField])"
                  type="button"
                  class="btn btn-danger"
                  @click="removeFilter(item[keyField])"
                >
                  Remove
                </CButton>
                <!-- <CBadge :color="getBadge(item.status)">
                  {{ item.status }}
                </CBadge> -->
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
      <slot slot="footer">
        <button type="button" class="btn btn-secondary" @click="closeModal()">
          Close
        </button>
      </slot>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { store } from "../store/store";
export default {
  name: "ModalSearch",
  components: {},
  props: {
    searchType: String,
    modalVisible: Boolean,
    modalTitle: String,
    filter: Array,
    keyField: String,
    nameField: String
  },

  created() {},
  computed: {
    tableFields() {
      if (this.resultTable[0]) {
        return this.tableFieldsAll.filter(
          (x) =>
            Object.keys(this.resultTable[0]).includes(x.key) ||
            x.key == "actions"
        );
      } else {
        return [];
      }
    },
    // shiptosFilter() {
    //   return store.state.filters.shiptos;
    // },
    // soldtosFilter() {
    //   return store.state.filters.soldtos;
    // },
  },
  data() {
    return {
      resultTable: [],
      tableFieldsAll: [
        {
          key: "snumber",
          label: "Number",
        },
        {
          key: "sname",
          label: "Name",
        },
        {
          key: "saddress",
          label: "Address",
        },
        {
          key: "fa_id",
          label: "Number",
        },
        {
          key: "fa_name",
          label: "Name",
        },
        {
          key: "vendor_id",
          label: "Number",
        },
        {
          key: "vendor_name",
          label: "Name",
        },
        {
          key: "actions",
          label: "Actions",
          sorter: false,
          filter: false,
        },
      ],
      searchStr: "",
    };
  },

  async mounted() {},

  methods: {
    async search() {
      var response = null;
      response = await Vue.prototype.$http.get(
        `api/filters/${this.searchType}/${this.searchStr}`
      );

      this.resultTable = response.data.data;
      console.log(response);
    },
    addFilter(number) {
      this.$emit("addFilter", number, this.searchType);

      store.commit("addToDictionary", {
        type: this.searchType,
        value: {
          key: number,
          value: this.resultTable.filter((x) => x[this.keyField] == number)[0][this.nameField],
        },
      });
    },
    removeFilter(number) {
      this.$emit("removeFilter", number, this.searchType);
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>


<style scoped>
</style>